import BreadCrumb from "Common/BreadCrumb";
import UserListTable from "Common/Tables/UserListTable";
import UserListTableAgent from "Common/Tables/UserListTableAgent";
import { FC, useEffect, useState } from "react";
import { Container, Card, Button, Row, Col, Form, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { authServices } from "Utils/auth/services";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import Select from "react-select";
import snackbarUtil from "Utils/snackBarUtil";
import { title } from "process";

interface Props {
    agent: boolean
}

const UserList: FC<Props> = ({ agent }) => {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<ChildLevelDetail[]>();
    const [userDataAgent, setUserDataAgent] = useState<userListAgentRes[]>([]);
    const [userId, setUserId] = useState<string>("");
    const [domainList, setDomainList] = useState<DomainListPayload[]>([]);
    const [domainId, setDomainId] = useState<string>("");
    const [levelId, setLevelId] = useState<any>(null);
    const user_Id = localStorage.getItem("userId");
    const [totalResult, setTotalResult] = useState<any>();
    const [totalData, setTotalData] = useState<any>();
    const [currentPage, setCurrentPage] = useState(1);
    const [formValuesFilter, setFormValuesFilter] = useState<any>({});
    const { searchQuery } = formValuesFilter;


    const [limit, setLimit] = useState<number>(50)
    useEffect(() => {
        if (user_Id) setUserId(user_Id);
    }, [user_Id]);

    const getUserList = async (filters: any = {}) => {
        if (!userId) return;
        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== "")
        );
        const { response } = await authServices.getUserListData(userId, {
            limit,
            page: currentPage,
            ...cleanedFilters,
        });

        setUserData(response?.data?.childLevelDetails);
        const totalPages = Math.ceil(response?.total / limit);
        setTotalResult(totalPages);
        setTotalData(response?.total);
    };

  


    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text("All User List", 10, 10);

        const userDataToExport = userData?.map((user, index) => [
            index + 1,
            user.user_name,
            user.domain?.site_title,
            user.credit_reference,
            user.liability,
            user.profit_loss,
            user.balance,
            user.point,
            user.user_type_id
        ]);

        const headers = ["No", "User Name", "Mobile", "CR", "Exposure", "Client P/L", "Client P/L %", "Point", "Avail. Balance"];

        autoTable(doc, {
            head: [headers],
            body: userDataToExport as any,
        });
        doc.save("user_data.pdf");
    };
    const downloadPDFAgent = () => {
        const doc = new jsPDF();
        doc.text("Agent List", 15, 15);

        const userDataToExport = userDataAgent?.map((user, index) => [
            index + 1,
            user.user_name,
            user.mobile,
            user.credit_reference,
            user.exposure,
            user.client_pl,
            user.client_pl_share,
            user.pts,
            user.available_pts
        ]);

        const headers = ["No", "User Name", "Domain Name", "CR", "Exposure", "P/L", "Balance", "Point", "C|L"];

        autoTable(doc, {
            head: [headers],
            body: userDataToExport as any,
        });
        doc.save("user_data_agent.pdf");
    };

    const downloadXML = () => {

        let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n<Users>\n`;

        userData?.forEach((user, id) => {
            xmlContent += `  <User>\n`;
            xmlContent += `    <No>${id + 1}</No>\n`;
            xmlContent += `    <UserName>${user.user_name}</UserName>\n`;
            xmlContent += `    <DomainName>${user.domain?.site_title}</DomainName>\n`;
            xmlContent += `    <CreditReference>${user.credit_reference}</CreditReference>\n`;
            xmlContent += `    <Exposure>${user.liability}</Exposure>\n`;
            xmlContent += `    <ProfitLoss>${user.profit_loss}</ProfitLoss>\n`;
            xmlContent += `    <Balance>${user.balance}</Balance>\n`;
            xmlContent += `    <Point>${user.point}</Point>\n`;
            xmlContent += `    <ChildLevel>${user.user_type_id}</ChildLevel>\n`;
            xmlContent += `  </User>\n`;
        });

        xmlContent += `</Users>`;

        const blob = new Blob([xmlContent], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'user_data.xml';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const downloadXMLAgent = () => {

        let xmlContent = `<?xml version="1.0" encoding="UTF-8"?>\n<Users>\n`;

        userDataAgent.forEach((user, id) => {
            xmlContent += `  <User>\n`;
            xmlContent += `    <No>${id + 1}</No>\n`;
            xmlContent += `    <UserName>${user.user_name}</UserName>\n`;
            xmlContent += `    <DomainName>${user.mobile}</DomainName>\n`;
            xmlContent += `    <CreditReference>${user.credit_reference}</CreditReference>\n`;
            xmlContent += `    <Exposure>${user.credit_reference}</Exposure>\n`;
            xmlContent += `    <ProfitLoss>${user.exposure}</ProfitLoss>\n`;
            xmlContent += `    <Balance>${user.client_pl}</Balance>\n`;
            xmlContent += `    <Point>${user.client_pl_share}</Point>\n`;
            xmlContent += `    <ChildLevel>${user.pts}</ChildLevel>\n`;
            xmlContent += `    <ChildLevel>${user.available_pts}</ChildLevel>\n`;
            xmlContent += `  </User>\n`;
        });

        xmlContent += `</Users>`;

        const blob = new Blob([xmlContent], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'user_data_agent.xml';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handleDownloadPdf = () => {
        if (agent) {
            downloadPDFAgent();
        } else {
            downloadPDF();
        }
    }
    const handleDownloadXml = () => {
        if (agent) {
            downloadXMLAgent();
        } else {
            downloadXML();
        }
    }

    const getUserListAgent = async (filters: any = {}) => {
        const cleanedFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== "")
        );

        const payload: any = {
            page: currentPage,
            limit: limit,
            user_id: userId,
            search: {
                ...cleanedFilters,
            },
        };

        if (payload.search.searchQuery) {
            payload.search.user_name = payload.search.searchQuery;
            delete payload.search.searchQuery;
        }

        if (payload.search.domainId) {
            payload.search.domain = payload.search.domainId;
            delete payload.search.domainId;
        }
        if (payload.search.levelId) {
            payload.search.title = payload.search.levelId;
            delete payload.search.levelId;
        }
        const { response } = await authServices.getAgentUserList(payload);
        setUserDataAgent(response?.data?.data);
        const totalPages = Math.ceil(response?.data?.metadata?.total / limit);
        setTotalResult(totalPages);
        setTotalData(response?.data?.metadata?.total);
    };

    const getAllWebsite = async () => {
        const { response } = await authServices.getAllWebsite();
        const options = response?.data.map((user: any) => ({
            value: user?._id,
            label: user.host_name,
        }));
        setDomainList(options);
    };

    useEffect(() => {
        if (userId?.length !== 0) {
            if (agent) {
                getUserListAgent({
                    ...(searchQuery && { searchQuery }),
                    ...(domainId && { domainId }),
                    ...(levelId && { levelId }),
                })
            } else {
                getUserList({
                    ...(searchQuery && { searchQuery }),
                    ...(domainId && { domainId }),
                    ...(levelId && { levelId }),
                });
            }
        }
    }, [userId, agent, limit, currentPage]);

    useEffect(() => {
        getAllWebsite();
    }, []);

    const handleDomain = (selectedOption: any) => {
        const selectedValue = selectedOption ? selectedOption.value : "";
        setDomainId(selectedValue);
        if (agent) {
            getUserListAgent({
                ...(searchQuery && { searchQuery }),
                domainId: selectedValue,
                ...(levelId && { levelId }),
            })
        } else {
            getUserList({
                ...(searchQuery && { searchQuery }),
                domainId: selectedValue,
                ...(levelId && { levelId }),
            });
        }

    };
    const handleLevel = (selectedOption: any) => {
        const selectedValue = selectedOption ? selectedOption.value : "";
        const selectedLabel = selectedOption ? selectedOption.label : "";
        if(agent){
            setLevelId(selectedOption ? selectedOption.label : null);
        }else{

            setLevelId(selectedOption ? selectedOption.value : null);
        }
        if (agent) {
            getUserListAgent({
                ...(searchQuery && { searchQuery }),
                ...(domainId && { domainId }),
                title: selectedLabel,
            })
        } else {
            getUserList({
                ...(searchQuery && { searchQuery }),
                ...(domainId && { domainId }),
                levelId: selectedValue,
            });
        }

    };

    useEffect(() => {
        if (searchQuery?.length >= 3 || searchQuery?.length === 0) {
            if (agent) {
                getUserListAgent({
                    ...(searchQuery && { searchQuery }),
                    ...(domainId && { domainId }),
                    ...(levelId && { levelId }),
                })
            } else {
                getUserList({
                    ...(searchQuery && { searchQuery }),
                    ...(domainId && { domainId }),
                    ...(levelId && { levelId }),
                });
            }
        }

    }, [searchQuery])




    






    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title={agent ? "User List" : "All User List"} pageTitle="Dashboard" back />
                <Card>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col lg={9}>
                                <Row className="align-items-end">
                                    {/* <Col lg={3}>
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            placeholder="Search Username"
                                            value={searchQuery}
                                            onChange={handleOnchange}
                                        />
                                        {searchError && <div className="text-danger">{searchError}</div>}
                                    </Col> */}
                                    {/* {
                                        !agent && <Col lg={3} className="mt-3 mt-lg-0">
                                            <Form.Label>Select Level</Form.Label>
                                            <Form.Select name="level" value={levelId} onChange={handleLevel}>
                                                <option value="">Select Level</option>
                                                {levelItem?.map((items) => (
                                                    <option value={items?.level} key={items?.name}>{items?.name}</option>
                                                ))}
                                            </Form.Select>
                                            {levelError && <div className="text-danger">{levelError}</div>}
                                        </Col>
                                    } */}

                                    {/* <Col lg={3} className="mt-3 mt-lg-0">

                                        <Form.Label>Domain</Form.Label>
                                        <Select
                                            isClearable
                                            options={domainList || []}
                                            className="customSelect"
                                            value={domainList.find((domain: any) => domain.value === domainId) || null}
                                            onChange={handleDomain}
                                        />
                                    </Col>
                                    <Col lg={3} className="mt-3 mt-lg-0">
                                        <Button variant="subtle-primary" onClick={handleSubmit}>Submit</Button>
                                        <Button variant="subtle-dark" className="ms-3" onClick={handleReset}>Reset</Button>
                                    </Col> */}
                                </Row>
                            </Col>
                            <Col lg={3} className="text-end mt-3 mt-lg-0 d-flex justify-content-end">
                                <Button variant="outline-primary" className="me-2" onClick={() => navigate('/add-user')}>
                                    <i className="fs-lg ri-user-line align-middle me-1"></i> User
                                </Button>
                                <Button variant="primary" className="me-2" onClick={() => navigate('/add-agent')}>
                                    <i className="fs-lg ri-group-line align-middle me-1"></i> Agent
                                </Button>
                                <Dropdown drop="start">
                                    <Dropdown.Toggle variant="subtle-dark" className="e-caret-hide">
                                        <i className="fs-lg ri-download-2-line"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item className="border-bottom py-2" onClick={handleDownloadPdf}>
                                            <i className="fs-lg me-2 bi bi-file-earmark-pdf-fill align-middle"></i> PDF
                                        </Dropdown.Item>
                                        <Dropdown.Item className="border-none py-2" onClick={handleDownloadXml}>
                                            <i className="fs-lg me-2 bi bi-file-earmark-excel-fill align-middle"></i> XLS
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card>
                    <Card.Body>
                        {
                            agent ?
                                <UserListTableAgent
                                    setLimit={setLimit}
                                    setUserId={setUserId}
                                    getUserList={getUserListAgent}
                                    userData={userDataAgent}
                                    limit={limit}
                                    totalResult={totalResult}
                                    totalPages={totalResult}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    totalData={totalData}
                                    setFormValuesFilter={setFormValuesFilter}
                                    formValuesFilter={formValuesFilter}
                                    domainList={domainList}
                                    domainId={domainId}
                                    handleDomain={handleDomain}
                                    setUserData={setUserDataAgent}
                                    handleLevel={handleLevel}
                                    levelId={levelId}
                                /> :
                                <UserListTable
                                    limit={limit}
                                    totalResult={totalResult}
                                    setLimit={setLimit}
                                    setUserId={setUserId}
                                    getUserList={getUserList}
                                    userData={userData}
                                    totalPages={totalResult}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    totalData={totalData}
                                    setFormValuesFilter={setFormValuesFilter}
                                    formValuesFilter={formValuesFilter}
                                    domainList={domainList}
                                    domainId={domainId}
                                    handleDomain={handleDomain}
                                    handleLevel={handleLevel}
                                    levelId={levelId}
                                    setUserData={setUserData}
                                />
                        }
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default UserList;
