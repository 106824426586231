import React, { useState } from 'react';
import { Card, Col, Button, Form } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

const ChangePassword = ({ logo }: any) => {
    const navigate = useNavigate();
    const userData = localStorage.getItem("adminDetails");

    const [oldPasswordShow, setOldPasswordShow] = useState<boolean>(false);
    const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);


    const [apiError, setApiError] = useState<string | null>(null);

    const userId =userData && JSON.parse(userData)

    console.log(userId, "userDatauserData")


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please enter your old password"),
            newPassword: Yup.string()
                .required("Please enter a new password")
                .min(6, "Password must be at least 6 characters long"),
            confirmPassword: Yup.string()
                .required("Please confirm your password")
                .oneOf([Yup.ref('newPassword')], "Passwords must match"),
        }),
        onSubmit: async (values) => {

            try {
                const changePayload = {
                    old_password: values?.oldPassword,
                    new_password: values.newPassword,
                    confirm_password: values.confirmPassword
                };
                const { response, error } = await authServices.changePassword(userId?._id,changePayload);
                if (response) {
                    if (response.status) {
                        snackbarUtil.success(response.msg)
                        navigate('/transaction-code');
                    }else{
                        snackbarUtil.error(response?.msg);
                    }
                }


            } catch (err) {
                console.log(err, "hui")
            }
        }
    });

    return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="6" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body className="p-sm-5 m-lg-4">
                            <div className='text-center d-xxl-none'>
                                <img src={`${process.env.REACT_APP_API_BASEURL}/${logo}`} alt="Logo" style={{ maxWidth: '260px' }} />
                            </div>
                            <div className="text-center mt-5">
                                <h5 className="fs-3xl">Change Password</h5>
                                <p className="text-muted">Change your password to continue to Backlay.</p>
                            </div>
                            <div className="p-2 mt-5">
                                <Form onSubmit={validation.handleSubmit}>

                                    {/* Old Password */}
                                    <Form.Group className="mb-3" controlId="formOldPassword">
                                        <Form.Label>Old Password <span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control
                                                type={oldPasswordShow ? "text" : "password"}
                                                className="form-control pe-5 password-input"
                                                placeholder="Enter old password"
                                                name="oldPassword"
                                                value={validation.values.oldPassword}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                isInvalid={validation.touched.oldPassword && !!validation.errors.oldPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.oldPassword}
                                            </Form.Control.Feedback>
                                            <Button
                                                variant="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                onClick={() => setOldPasswordShow(!oldPasswordShow)}
                                            >
                                                <i className={`${oldPasswordShow?"ri-eye-fill":"ri-eye-off-fill"} align-middle`}></i>
                                            </Button>
                                        </div>
                                    </Form.Group>

                                    {/* New Password */}
                                    <Form.Group className="mb-3" controlId="formNewPassword">
                                        <Form.Label>New Password <span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control
                                                type={newPasswordShow ? "text" : "password"}
                                                className="form-control pe-5 password-input"
                                                placeholder="Enter new password"
                                                name="newPassword"
                                                value={validation.values.newPassword}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                isInvalid={validation.touched.newPassword && !!validation.errors.newPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.newPassword}
                                            </Form.Control.Feedback>
                                            <Button
                                                variant="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                onClick={() => setNewPasswordShow(!newPasswordShow)}
                                            >
                                               <i className={`${newPasswordShow?"ri-eye-fill":"ri-eye-off-fill"} align-middle`}></i>
                                            </Button>
                                        </div>
                                    </Form.Group>

                                    {/* Confirm Password */}
                                    <Form.Group className="mb-3" controlId="formConfirmPassword">
                                        <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                            <Form.Control
                                                type={confirmPasswordShow ? "text" : "password"}
                                                className="form-control pe-5 password-input"
                                                placeholder="Confirm new password"
                                                name="confirmPassword"
                                                value={validation.values.confirmPassword}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                isInvalid={validation.touched.confirmPassword && !!validation.errors.confirmPassword}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validation.errors.confirmPassword}
                                            </Form.Control.Feedback>
                                            <Button
                                                variant="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                                            >
                                                 <i className={`${confirmPasswordShow?"ri-eye-fill":"ri-eye-off-fill"} align-middle`}></i>
                                            </Button>
                                        </div>
                                    </Form.Group>

                                    {/* Submit Button */}
                                    <div className="mt-4">
                                        <Button className="btn btn-primary w-100" type="submit">
                                            Change Password
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    );
};

export default ChangePassword;
