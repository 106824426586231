import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState, useMemo } from "react";
import { authServices } from "Utils/auth/services";
import {
  clearSearchPayload,
  generateOptions,
  updateSearchPayload,
} from "Utils/searchFilter";
import SettledBetsTable from "Common/Tables/SettledBetsTable";
import { useParams } from "react-router-dom";

const options = [
  { value: "Sport", label: "Sport Name" },
  { value: "Series", label: "Series Name" },
  { value: "Match", label: "Match Name" },
  { value: "Market", label: "Market Name" },
];

interface StatementProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const SettledBets = ({
  filter,
  activeTab,
  isReport = false,
}: StatementProps) => {
  const { userid } = useParams();

  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [settledBetsData, setSettledBetsData] = useState<OpenBetsData[]>([]);
  const [searchList, setSearchList] = useState<SportSearchList>();
  const [searchPayload, setSearchPayload] = useState<SportsSearchPayloadData>(
    {}
  );
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const eventOptions = useMemo(
    () =>
      generateOptions(
        searchList?.events_m_f || [],
        "event_id",
        "event_name",
        "type"
      ),
    [searchList?.events_m_f]
  );
  const matchOptions = useMemo(
    () => generateOptions(searchList?.matches || [], "match_id", "match_name"),
    [searchList?.matches]
  );
  const seriesOptions = useMemo(
    () => generateOptions(searchList?.series || [], "series_id", "series_name"),
    [searchList?.series]
  );
  const sportsOptions = useMemo(
    () => generateOptions(searchList?.sports || [], "sport_id", "sport_name"),
    [searchList?.sports]
  );

  const getSettledBetsList = async (filters: any = {}) => {
    const { response } = await authServices.getSettledBetsList({
      limit: limit,
      page: page,
      ...filters,
    });
    if (response?.data && response?.data?.data) {
      setSettledBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]?.total / (filters?.limit || limit),
        page: response?.data?.metadata[0]?.page,
      });
    } else {
      setSettledBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "settledBets",
      ...filters,
    });
    if (response?.data && response?.data.length > 0) {
      setSearchList(response?.data[0]);
    }
  };

  useEffect(() => {
    if (activeTab === "SettledBets") {
      getSettledBetsList({
        ...(!isReport && {
          from_date: startDate,
          to_date: endDate,
        }),
        ...(userid && userid != "null" && { search: { user_id: userid } }),
      });
      getSportSearchList();
    }
  }, [activeTab]);

  const handlefilterSubmit = () => {
    let filterData: SportsSearchPayloadData = searchPayload;
    if (userid && userid != "null") filterData.user_id = userid;

    getSettledBetsList({
      from_date: startDate,
      to_date: endDate,
      search: filterData,
    });
  };

  const handleSearchFilter = (type: SearchPayloadType, id: string) => {
    const payload = updateSearchPayload(type, id, searchPayload);
    if (userid && userid != "null") payload.user_id = userid;

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    getSettledBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      search: payload,
    });
  };

  const handleClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    if (userid && userid != "null") payload.user_id = userid;

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    getSettledBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      search: payload,
    });
  };

  const handleAllClearSelection = (type: SearchPayloadType) => {
    const payload = clearSearchPayload(type, searchPayload);
    if (userid && userid != "null") payload.user_id = userid;

    getSportSearchList({ search: payload });
    setSearchPayload(payload);
    setStartDate(getStartTimeOfDay(new Date()));
    setEndDate(getLastTimeOfDay(new Date()));

    getSettledBetsList({
      ...(!isReport && {
        from_date: getStartTimeOfDay(new Date()),
        to_date: getLastTimeOfDay(new Date()),
      }),
      search: payload,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    let filterData: SportsSearchPayloadData = searchPayload;
    if (userid && userid != "null") filterData.user_id = userid;

    setPage(page);
    setLimit(limit);
    getSettledBetsList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      page: page,
      limit: limit,
      search: filterData,
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Sport Name</Form.Label>
                  <Select
                    isClearable
                    options={sportsOptions}
                    value={
                      sportsOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.sport_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("sport_id", e.value);
                      } else {
                        handleClearSelection("sport_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Series Name</Form.Label>
                  <Select
                    isClearable
                    options={seriesOptions}
                    value={
                      seriesOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.series_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("series_id", e.value);
                      } else {
                        handleClearSelection("series_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Match Name</Form.Label>
                  <Select
                    isClearable
                    options={matchOptions}
                    value={
                      matchOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.match_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter("match_id", e.value);
                      } else {
                        handleClearSelection("match_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search Market Name</Form.Label>
                  <Select
                    isClearable
                    options={eventOptions}
                    value={
                      eventOptions.find(
                        (option: any) =>
                          option.value === searchPayload?.fancy_id ||
                          option.value === searchPayload?.market_id
                      ) || null
                    }
                    className="customSelect"
                    onChange={(e: any) => {
                      if (e) {
                        handleSearchFilter(
                          e.type == "1" ? "market_id" : "fancy_id",
                          e.value
                        );
                      } else {
                        handleClearSelection("market_id");
                      }
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleAllClearSelection("sport_id")}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <SettledBetsTable
            settledBetsData={settledBetsData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default SettledBets;
