import BreadCrumb from "Common/BreadCrumb";
import { Container, Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";
import FancyStakeTable from "Common/Tables/FancyStakeTable";

const FancyStake = () => {
  const { marketid, userid } = useParams();
  const [sportsData, setSportsData] = useState<FancyStakeList[]>([]);
  const [parentId, setParentId] = useState<string>("null");
  const [total, setTotal] = useState<number>(0);

  const userId =
    userid && userid != "null" ? userid : localStorage.getItem("userId");

  const getFancyList = async (filters: any = {}) => {
    const { response } = await authServices.getFancyStackUserWise({
      user_id: userId,
      event_id: marketid,
      ...filters,
    });
    if (response?.data) {
      setSportsData(response?.data);
      setParentId(response?.parent_id);
      let plList = response?.data;
      var totalStack = 0;
      for (let i = 0; i < plList.length; i++) {
        totalStack = totalStack + plList[i].stack;
      }
       setTotal(totalStack);
    }
  };

  useEffect(() => {
    getFancyList();
  }, []);

  const handleUserDetail = (user: string) => {
    getFancyList({
      user_id: user && user != "null" ? user : userId,
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb title={"Fancy Stake"} pageTitle="Dashboard" back />
        </div>
        <>
          <Card>
            <Card.Body>
              <FancyStakeTable
                fancyStakeData={sportsData}
                total={total}
                parentId={parentId}
                handleUserDetail={handleUserDetail}
              />
            </Card.Body>
          </Card>
        </>
      </Container>
    </div>
  );
};

export default FancyStake;
