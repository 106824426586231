import Flatpickr from "react-flatpickr";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import FancyStakeTable from "Common/Tables/FancyStakeTable";
import { useParams } from "react-router-dom";

interface FancyStakeUserWiseProps {
  filter?: boolean;
  activeTab: string;
}

const FancyStakeUserWise = ({ filter, activeTab }: FancyStakeUserWiseProps) => {
  const { userid } = useParams();

  const userId =
    userid && userid != "null" ? userid : localStorage.getItem("userId");

  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [fancyStakeUserWiseData, setFancyStakeUserWiseData] = useState<
    FancyStakeList[]
  >([]);
  const [parentId, setParentId] = useState<string>("null");
  const [total, setTotal] = useState<number>(0);

  const getFancyStakeUserWiseList = async (filters: any = {}) => {
    const { response } = await authServices.getFancyTotalStackUserWise({
      user_id: userId,
      ...filters,
    });
    if (response?.data) {
      setFancyStakeUserWiseData(response?.data);
      setParentId(response?.parent_id);

      let stackList = response?.data;
      var totalStack = 0;
      for (let i = 0; i < stackList.length; i++) {
        totalStack = totalStack + stackList[i].stack;
      }
      setTotal(totalStack);
    }
  };

  useEffect(() => {
    activeTab === "FancyStakeU" &&
      getFancyStakeUserWiseList({
        from_date: startDate,
        to_date: endDate,
      });
  }, [activeTab]);

  const handlefilterSubmit = () => {
    getFancyStakeUserWiseList({
      from_date: startDate,
      to_date: endDate,
    });
  };

  const handleClearSelection = () => {
    setStartDate(getStartTimeOfDay(new Date()));
    setEndDate(getLastTimeOfDay(new Date()));

    getFancyStakeUserWiseList({
      from_date: getStartTimeOfDay(new Date()),
      to_date: getLastTimeOfDay(new Date()),
    });
  };

  const handleUserDetail = (user: string) => {
    getFancyStakeUserWiseList({
      user_id: user && user != "null" ? user : userId,
    });
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search UserName</Form.Label>
                  <Select isClearable options={[]} className="customSelect" />
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <FancyStakeTable
            fancyStakeData={fancyStakeUserWiseData}
            total={total}
            parentId={parentId}
            handleUserDetail={handleUserDetail}
            isStatement={true}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default FancyStakeUserWise;
