import { useState, useCallback, useMemo } from 'react';
import { Table, Form, InputGroup, Button, Pagination } from 'react-bootstrap';
import { authServices } from 'Utils/auth/services';
import snackbarUtil from 'Utils/snackBarUtil';

const ShowFancyTable = ({ data, getFancyLiveData }: any) => {
  const [active, setActive] = useState<{ [key: string]: number }>({});
  const [fancyDetails, setFancyDetails] = useState<{ [key: string]: { category: string; chronology: string } }>({});
  const [errors, setErrors] = useState<{ [key: string]: { category?: string; chronology?: string } }>({});
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15; // Items to display per page

  const user_id = useMemo(() => localStorage.getItem("userId") || "", []);

  const updateFancyStatus = async (fancy_id: string, is_active: string) => {
    try {
      const { response } = await authServices.updateFancyStatus({
        fancy_id,
        user_id,
        is_active,
      });
      if (response?.status) {
        snackbarUtil.success(response.msg);
        getFancyLiveData();
      } else {
        snackbarUtil.error(response.msg);
      }
    } catch (error) {
      console.error("Failed to update fancy status", error);
      snackbarUtil.error("Failed to update status");
    }
  };

  const updateFancyDetails = async (category: string, chronology: string, fancy_id: string) => {
    if (!category || !chronology) {
      setErrors(prev => ({
        ...prev,
        [fancy_id]: {
          category: !category ? 'Category is required' : undefined,
          chronology: !chronology ? 'Chronology is required' : undefined,
        },
      }));
      return;
    }

    try {
      const { response } = await authServices.updateFancy({
        category,
        chronology,
        fancy_id,
      });

      if (response?.status) {
        snackbarUtil.success("Updated Successfully...");
        setFancyDetails(prev => ({
          ...prev,
          [fancy_id]: { category: '', chronology: '' },
        }));
        setErrors(prev => ({ ...prev, [fancy_id]: {} }));
      } else {
        snackbarUtil.error(response?.msg);
      }
    } catch (error) {
      console.error("Failed to update fancy", error);
      snackbarUtil.error("Update failed");
    }
  };

  const handleToggleStatus = useCallback(
    (fancyId: string, is_active: number) => {
      const newStatus = is_active === 1 ? 0 : 1;
      setActive(prevState => ({
        ...prevState,
        [fancyId]: newStatus,
      }));
      updateFancyStatus(fancyId, newStatus.toString());
    },
    [updateFancyStatus]
  );

  const handleFancyUpdate = useCallback(
    (fancy_id: string) => {
      const { category, chronology } = fancyDetails[fancy_id] || { category: '', chronology: '' };
      updateFancyDetails(category, chronology, fancy_id);
    },
    [fancyDetails]
  );

  const handleCategoryChange = useCallback((fancy_id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFancyDetails(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        category: e.target.value,
      },
    }));

    setErrors(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        category: undefined,
      },
    }));
  }, []);

  const handleChronologyChange = useCallback((fancy_id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setFancyDetails(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        chronology: e.target.value,
      },
    }));
    setErrors(prev => ({
      ...prev,
      [fancy_id]: {
        ...prev[fancy_id],
        chronology: undefined,
      },
    }));
  }, []);

  // Pagination logic
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = data?.slice(startIndex, startIndex + itemsPerPage);

 
  const getPaginationItems = () => {
    let start = Math.max(currentPage - 1, 1);
    let end = Math.min(currentPage + 1, totalPages);
    

    if (currentPage === 1) {
      end = Math.min(3, totalPages);
    } 

    else if (currentPage === totalPages) {
      start = Math.max(totalPages - 2, 1);
    }

    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  };

  const paginationItems = getPaginationItems();

  return (
    <div className='mt-3'>
      <div className="table-responsive">
        <Table className="align-middle table-striped table-nowrap mb-0">
          <thead className='table-light'>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Fancy Name</th>
              <th scope="col">Action</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((fancy: any, index: number) => {
              const isActive = active[fancy.fancy_id] ?? fancy.is_active;
              const { category = '', chronology = '' } = fancyDetails[fancy.fancy_id] || {};

              return (
                <tr key={fancy.fancy_id}>
                  <td>{startIndex + index + 1}</td>
                  <td className="w-50">{fancy.name}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      id={`action-${fancy.fancy_id}`}
                      checked={isActive === 1}
                      onChange={() => handleToggleStatus(fancy.fancy_id, isActive)}
                    />
                  </td>
                  <td>
                    <InputGroup className='flex-nowrap'>
                      <Form.Control
                        onChange={handleCategoryChange(fancy.fancy_id)}
                        value={category}
                        type="text"
                        placeholder="Type"
                        className='p-1 text-center'
                        style={{minWidth: '45px'}}
                        isInvalid={!!errors[fancy.fancy_id]?.category}
                      />
                      <Form.Control
                        onChange={handleChronologyChange(fancy.fancy_id)}
                        value={chronology}
                        type="text"
                        placeholder="Order"
                        className='p-1 text-center'
                        style={{minWidth: '55px'}}
                        isInvalid={!!errors[fancy.fancy_id]?.chronology}
                      />
                      <Button
                        variant="subtle-success"
                        onClick={() => handleFancyUpdate(fancy.fancy_id)}
                      >
                        U-C
                      </Button>
                      <Form.Control.Feedback type="invalid">
                        {errors[fancy.fancy_id]?.category || errors[fancy.fancy_id]?.chronology}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Pagination className='mt-3'>
        <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
        <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
        {paginationItems.map(page => (
          <Pagination.Item 
            key={page} 
            active={page === currentPage} 
            onClick={() => setCurrentPage(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
        <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
      </Pagination>
    </div>
  );
};

export default ShowFancyTable;
