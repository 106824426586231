import { Button, Modal, Tab, Nav } from 'react-bootstrap';
import MarketSettingForm from './MarketSettingForm';
import { authServices } from 'Utils/auth/services';
import { useEffect, useState, useSyncExternalStore } from 'react';
import snackbarUtil from 'Utils/snackBarUtil';

interface UserMarketLimitProps {
    show: any;
    clickHandler: any;
    userIds: string;
    settingData: string;
    userName: string
}

interface SportSetting {
    sport_id: string;
    name: string;
    settings: any;
}

interface SportSettingsByName {
    [key: string]: SportSetting;
}

const UserMarketLimit = ({ show, clickHandler, userIds, settingData, userName }: UserMarketLimitProps) => {
    const [combineData, setCombineData] = useState<SportSettingsByName>({});
    const [combineDataParent, setCombineDataParent] = useState<SportSettingsByName>({});
    const [validationError, setValidationError] = useState<any>();
    const [tooltipMsg, setTooltipMsg] = useState<{ [key: string]: string }>({});
    const [showTooltip, setShowTooltip] = useState<{ [key: string]: boolean }>({});
    const [formValues, setFormValues] = useState<string>("Cricket");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState<any>({
        market_advance_bet_stake: "",
        market_bet_delay: "",
        market_bookmaker_max_odds_rate: "",
        market_bookmaker_min_odds_rate: "",
        market_max_profit: "",
        market_max_stack: "",
        market_min_odds_rate: "",
        market_min_stack: "",
        session_bet_delay: "",
        session_max_profit: "",
        session_max_stack: "",
        session_min_stack: "",
        market_max_odds_rate: ""
    });

    const desiredOrder = [
        "Cricket", "Soccer", "Tennis", "Horse Racing",
        "Greyhound Racing", "Share Market", "Casino", "D Casino"
    ];


    const getUserLimit = async () => {
        try {
            const { response } = await authServices.getUserLimits({
                _id: settingData,
                user_id: userIds,
            });
            const groupedSports = getSportsByNames(response?.data?.sports_settings || []);
            const parentGroup = getSportsByNames(response?.data?.parent_sports_settings || []);
            setCombineData(groupedSports);
            setCombineDataParent(parentGroup);


        } catch (error) {
            console.error('Error fetching user limits:', error);
        }
    };
    useEffect(() => {
        if (show) {

            getUserLimit();
        }
    }, [userIds, settingData, show]);
    function getSportsByNames(sportsSettings: SportSetting[]): SportSettingsByName {
        const sortedSportsSettings = sportsSettings?.sort((a, b) => {
            return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
        });

        return sortedSportsSettings?.reduce((acc: SportSettingsByName, setting: SportSetting) => {
            acc[setting.name] = setting;
            return acc;
        }, {});

    }

    const handleClickSportName = (name: string) => {
        setFormValues(name);
    }

    const validateForm = () => {
        const newErrors: any = {};

        const keysToValidate = formValues === "Cricket"
            ? Object.keys(formData)
            : Object.keys(formData).filter(key => !key.startsWith("session"));

        keysToValidate.forEach((key) => {
            const value = formData[key];
            if (value === "" || value === null || (typeof value === 'number' && value <= 0)) {
                newErrors[key] = "Value must be greater than 0.";
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // const handleTooltipDisplay = () => {
    //     const errorsToShow: { [key: string]: boolean } = {};
    //     for (const key in validationError) {
    //         if (validationError?.hasOwnProperty(key)) {
               
    //             const shortError = validationError[key][0];
    //             tooltipMsg[key] = shortError; 
    //             errorsToShow[key] = true;
    //         }
    //     }

    //     setShowTooltip(errorsToShow);
    // };

    const getUserSettingUpdate = async () => {
        const { response } = await authServices?.getUserSettingUpdate({
            user_id: userIds,
            sport_id: combineData[formValues]?.sport_id,
            sports_settings: [
                {
                    ...(formValues !== "Cricket" ? {
                        market_advance_bet_stake: formData?.market_advance_bet_stake,
                        market_bet_delay: formData?.market_bet_delay,
                        market_max_odds_rate: formData?.market_max_odds_rate,
                        market_max_profit: formData?.market_max_profit,
                        market_max_stack: formData?.market_max_stack,
                        market_min_odds_rate: formData?.market_min_odds_rate,
                        market_min_stack: formData?.market_min_stack,
                    } : formData)
                }
            ]
        })



        if (response) {
            if (response?.is_validation_error) {
                setValidationError(response?.data);
                // handleTooltipDisplay();
                // const Data = Object.keys(response?.data);
                // if (Data.length > 0) {
                //     const shortError = response.data[Data[0]]?.[0]?.short_error;
                //     snackbarUtil.error(`${Data[0]} value ${shortError}`);
                // }
            }
            if (response?.status) {
                snackbarUtil.success(response?.msg);
                clickHandler();
            } else {
                snackbarUtil.error(response?.msg);
            }
        }


    }

    const handleSave = () => {
        const hasActiveTooltips = Object.values(showTooltip).some((isTooltipVisible) => isTooltipVisible);
        if (hasActiveTooltips || !validateForm()) {
            return;
        }
        if (validateForm()) {
            getUserSettingUpdate();

        }
    };


    console.log(showTooltip, "newShowTooltipnewShowTooltip")


    return (
        <Modal size='lg' show={show} onHide={clickHandler} className="zoomIn" scrollable>
            <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
                <span>User Sports Settings ({userName})</span>
                <Button variant="light btn-sm" onClick={clickHandler}>
                    <i className="ri-close-line fs-xl align-middle"></i>
                </Button>
            </Modal.Header>
            <Modal.Body className='pt-2 fs-md'>
                <Tab.Container defaultActiveKey="Cricket">
                    <Nav as="ul" variant="pills" className="arrow-navtabs nav-success bg-light mb-3">
                        {
                            combineData && Object.keys(combineData)?.map((key) => (
                                <Nav.Item as="li" key={key} onClick={() => handleClickSportName(key)}>
                                    <Nav.Link eventKey={key}>{key}</Nav.Link>
                                </Nav.Item>
                            ))
                        }
                    </Nav>
                    <Tab.Content className="text-muted">

                        <Tab.Pane eventKey={formValues} key={formValues}>
                            <MarketSettingForm
                                sportName={formValues}
                                combineData={combineData[formValues]}
                                setFormData={setFormData}
                                formData={formData}
                                setErrors={setErrors}
                                errors={errors}
                                validationError={validationError}
                                combineDataParent={combineDataParent[formValues]}
                                setTooltipMsg={setTooltipMsg}
                                tooltipMsg={tooltipMsg}
                                setShowTooltip={setShowTooltip}
                                showTooltip={showTooltip}
                            />
                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>
            </Modal.Body>
            <Modal.Footer className='border-top'>
                <Button onClick={handleSave}>Update</Button>
                <Button variant='light' onClick={clickHandler}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserMarketLimit;
