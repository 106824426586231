import StatementTable from "Common/Tables/StatementTable";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { authServices } from "Utils/auth/services";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import { useParams } from "react-router-dom";

interface StatementProps {
  filter?: boolean;
  activeTab: string;
  isReport?: boolean;
}

const Statement = ({ filter, activeTab, isReport = false }: StatementProps) => {
  const { userid } = useParams();
  const [statementData, setStatementData] = useState<StatementListData[]>([]);
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [statementType, setStatementType] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const getStatementList = async (filters: any = {}) => {
    const { response } = await authServices.getStatementList({
      limit: limit,
      page: page,
      ...(userid && userid != "null" && { user_id: userid } ),
      ...filters,
    });
    if (response?.data && response?.data?.length > 0) {
      setStatementData(response?.data[0]?.data);
      setListTotal({
        total:
          response?.data[0]?.metadata[0]?.total /
          response?.data[0]?.metadata[0]?.limit,
        page: response?.data[0]?.metadata[0]?.page,
      });
    } else {
      setStatementData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  useEffect(() => {
    activeTab === 'Statement' && getStatementList({
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
    });
  }, [activeTab, limit]);

  const handlefilterSubmit = () => {
    let filterData: {
      from_date: string;
      to_date: string;
      statement_type?: string;
    } = {
      from_date: startDate,
      to_date: endDate,
    };
    if (statementType != "") filterData.statement_type = statementType;

    getStatementList(filterData);
  };

  const handleClearSelection = () => {
    getStatementList({
      ...(!isReport && {
        from_date: getStartTimeOfDay(new Date()),
        to_date: getLastTimeOfDay(new Date()),
      }),
    });
    setStartDate(getStartTimeOfDay(new Date()));
    setEndDate(getLastTimeOfDay(new Date()));
    setStatementType('');
  };

  const handleStatementType = (type: string) => {
    let filterData: {
      from_date?: string;
      to_date?: string;
      statement_type?: string;
    } = {
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
    };
    if (type != "") filterData.statement_type = type;

    setStatementType(type);
    getStatementList(filterData);
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let filterData: {
      from_date?: string;
      to_date?: string;
      page: number;
      limit: number;
      statement_type?: string;
    } = {
      ...(!isReport && {
        from_date: startDate,
        to_date: endDate,
      }),
      page: page,
      limit: limit,
    };
    if (statementType != "") filterData.statement_type = statementType;

    getStatementList(filterData);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <div className="d-flex align-items-center flex-wrap mb-3">
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Statement"
                    defaultChecked={statementType === ""}
                    onChange={() =>
                      statementType !== "" && handleStatementType("")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Statement">
                    Statement
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="FreeChips"
                    defaultChecked={statementType === "1"}
                    onChange={() =>
                      statementType !== "1" &&
                      handleStatementType(statementType === "1" ? "" : "1")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="FreeChips">
                    Free Chips
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="ProfitLoss"
                    defaultChecked={statementType === "2"}
                    onChange={() =>
                      statementType !== "2" &&
                      handleStatementType(statementType === "2" ? "" : "2")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="ProfitLoss">
                    Profit Loss
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Commission"
                    defaultChecked={statementType === "3"}
                    onChange={() =>
                      statementType !== "3" &&
                      handleStatementType(statementType === "3" ? "" : "3")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Commission">
                    Commission
                  </Form.Label>
                </div>
                <div className="form-check me-3">
                  <Form.Check
                    type="radio"
                    name="statement"
                    id="Settlement"
                    defaultChecked={statementType === "4"}
                    onChange={() =>
                      statementType !== "4" &&
                      handleStatementType(statementType === "4" ? "" : "4")
                    }
                  />
                  <Form.Label className="form-check-label" htmlFor="Settlement">
                    Settlement
                  </Form.Label>
                </div>
              </div>
              <Row className="gy-3 align-items-end">
                <Col lg={9}>
                  <Row className="gap-3 gap-sm-0">
                    <Col lg={4}>
                      <Form.Label>Start Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        placeholder="Select Date"
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [startDate],
                        }}
                        value={startDate}
                        onChange={([date]: [Date]) => {
                          setStartDate(getStartTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>End Date</Form.Label>
                      <Flatpickr
                        className="form-control"
                        pl
                        options={{
                          enableTime: false,
                          dateFormat: "Y-m-d",
                          defaultDate: [endDate],
                        }}
                        value={endDate}
                        onChange={([date]: [Date]) => {
                          setEndDate(getLastTimeOfDay(new Date(date)));
                        }}
                      />
                    </Col>
                    <Col lg={4}>
                      <Form.Label>Search By Username</Form.Label>
                      <Form.Control type="text" placeholder="Username" />
                    </Col>
                  </Row>
                </Col>
                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <StatementTable
            statementData={statementData}
            page={page}
            limit={limit}
            listTotal={listTotal}
            handlePageFilter={handlePageFilter}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Statement;
