import React, { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import TableContainer from "../TableContainer";
import { Button, Col, Form, Modal, Pagination, Row } from "react-bootstrap";
import moment from "moment";
import TableEntry from "Common/TableEntry";
import { ListPagination } from "Common/ListPagination";

interface Props {
  betsDetails: BetsData | undefined;
  getDeleteBet: any;
  setPayloadData: Dispatch<
    SetStateAction<{
      bet_id: string;
      user_id: string;
      is_void: boolean;
      is_fancy: number;
      password: string;
    }>
  >;
  paylodData: {
    bet_id: string;
    user_id: string;
    is_void: boolean;
    is_fancy: number;
    password: string;
  };
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
 
 
 

  setFormValuesFilter: any;
  formValuesFilter: any;
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  handlePageFilter: (value: number, limit: number) => void;
}

const MatchDetailTable: FC<Props> = ({
  formValuesFilter,
  setFormValuesFilter,

  setActive,
  active,
  setPayloadData,
  paylodData,
  betsDetails,
  getDeleteBet,
  page,
  limit,
  listTotal,
  handlePageFilter,
}) => {
  const [copied, setCopied] = useState(false);
  const [showPassword, setPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, []);

  const filteredData = useMemo(() => {
    if (!betsDetails || !betsDetails.data) return [];

    const lowercasedSearchValue = searchValue.toLowerCase();
    return betsDetails.data.filter(
      (item) =>
        item.user_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.domain_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.market_name.toLowerCase().includes(lowercasedSearchValue) ||
        item.selection_name.toLowerCase().includes(lowercasedSearchValue)
    );
  }, [betsDetails, searchValue]);

  const handleOpenModals = (
    bet_id: string,
    user_id: string,
    is_fancy: number
  ) => {
    setPayloadData((prev) => ({
      ...prev,
      bet_id,
      user_id,
      is_fancy,
    }));
    setActive(true);
  };

  const handlePassValue = (e: any) => {
    setPayloadData((prev) => ({
      ...prev,
      password: e.target.value,
    }));
    setValidated(false);
  };

  const handleDeletedBet = (is_void: boolean) => {
    if (paylodData?.password.trim() === "") {
      setValidated(true);
      return;
    }
    setPayloadData((prev) => ({
      ...prev,
      is_void,
    }));
    getDeleteBet();
  };

  const columns = useMemo(
    () => [
      {
        header: "No.",
        accessorKey: "sNo",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const serialNumber =
            (page - 1) * limit + cell.row.index + 1;
          return (
            <span>
              {serialNumber}
              {cell?.row?.original?.delete_status === 0 && (
                <i
                  onClick={() =>
                    handleOpenModals(
                      cell?.row?.original?.bet_id,
                      cell?.row?.original?.user_id,
                      cell.row.original.is_fancy
                    )
                  }
                  // className="cursor-pointer text-danger ri-delete-bin-line ms-2"
                ></i>
              )}
              {cell.getValue()}
            </span>
          );
        },
      },
      {
        header: "Name",
        accessorKey: "user_name",
        enableColumnFilter: true,
        cell: (cell: any) => <div>{cell?.row?.original?.user_name}</div>,
      },
      {
        header: "Domain",
        accessorKey: "domain_name",
        enableColumnFilter: true,
      },
      {
        header: "Market | Selection",
        accessorKey: "selection_name",
        enableColumnFilter: true,
        cell: (cell: any) => (
          <>
            <div>{cell?.row?.original?.market_name}</div>
            <div>{cell?.row?.original?.selection_name}</div>
          </>
        ),
      },
      {
        header: "Type",
        accessorKey: "is_back",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <Button
            variant="sm"
            className={
              cell?.row?.original?.is_back === 1 ? "btn-back" : "btn-lay"
            }
          >
            {cell?.row?.original?.is_back === 1 ? "Back" : "Lay"}
          </Button>
        ),
      },
      {
        header: "Rate | Stake",
        accessorKey: "odds",
        enableColumnFilter: true,
        cell: (cell: any) => (
          <>
            <div>
              {cell?.row?.original?.odds}/{cell?.row?.original?.size}
            </div>
            <div>{cell?.row?.original?.stack}</div>
          </>
        ),
      },
      {
        header: "Liability | Placed Time",
        accessorKey: "Liability",
        enableColumnFilter: true,
        cell: (cell: any) => (
          <>
            <span>
              <span
                className={`${
                  cell?.row?.original?.liability < 0
                    ? "text-danger"
                    : "text-success"
                } me-2`}
              >
                {cell?.row?.original?.liability?.toFixed(2)}
              </span>
            </span>
            {cell.getValue()}
          <div>{moment(cell?.row?.original?.createdAt).format('DD-MM-YY hh:mm A')}</div>
        </>
      ),
    },
    {
      header: "BetId | IP Address",
      accessorKey: "ip_address",
      enableColumnFilter: true,
      cell: (cell: any) => (
        <>
          <div>{cell?.row?.original?.bet_id}</div>
          <div>{cell?.row?.original?.ip_address}</div>
        </>
      ),
    },
  ], [page, limit]);

  return (
    <React.Fragment>
    
      <div className="mt-3">
      </div>


      <TableContainer
        customPageSize={limit}
        columns={columns}
        data={filteredData || []}
        isPagination={false}
        isBordered={false}
        sorting={false}
        isFilter={true}
        tableClass="table-centered align-middle mb-0 react-table table-striped table-nowrap matchDetailAllBet"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        rowClass={"backBorder"}
        showBck={true}
        tdClass="text-dark"
        setFormValuesFilter={setFormValuesFilter}
        formValuesFilter={formValuesFilter}
      />

      <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
        <TableEntry
          limit={limit}
          handlelimitFilter={(value: number) => handlePageFilter(1, value)}
        />
        <ListPagination
          listTotal={listTotal}
          handlePageFilter={(value: number) => handlePageFilter(value, limit)}
        />
      </nav>

      <Modal show={active} className="zoomIn" scrollable onHide={() => setActive(false)}>
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
            <span>Delete Bet</span>
            <Button variant="light btn-sm" onClick={() => setActive(false)}>
                <i className="ri-close-line fs-xl align-middle"></i>
            </Button>
        </Modal.Header>
        <Modal.Body className="text-center ">
          <Form noValidate>
            <div className="form-floating">
              <Form.Control
                isInvalid={validated && paylodData?.password?.trim() === ""}
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={paylodData?.password}
                onChange={handlePassValue}
                required
              />
              <Form.Label>Enter New Password</Form.Label>
              <Button
                variant="link"
                className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                type="button"
                id="password-addon"
                onClick={() => setPassword(!showPassword)}
              >
                <i className={`${showPassword?"ri-eye-fill":"ri-eye-off-fill"} align-middle`}></i>
              </Button>
              <Form.Control.Feedback type="invalid">
                Please enter your password.
              </Form.Control.Feedback>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          
            <Button
              variant="light"
              className="mx-2"
              onClick={() => setActive(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" className="mx-2" onClick={() => handleDeletedBet(false)}>
              Delete
            </Button>
            <Button className="mx-2" onClick={() => handleDeletedBet(true)}>
              Void
            </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default MatchDetailTable;
