import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface DepositModalProps {
  show: any;
  clickHandler: any;
  userIds: string;
  getUserList: () => Promise<void>;
  userName: string;
  setDepositWithData: any;
  depositWithData: number;
  userTypeId?: number;
  updateUserData?: (userId: string, newBalance: number) => void;
}

interface DepositData {
  balance: number;
  parent_id: {
    _id: string;
    user_name: string;
    balance: number;
  };
  name: string;
  credit_reference: number;
}

const WithdrawModal = ({
  setDepositWithData,
  depositWithData,
  show,
  clickHandler,
  userIds,
  getUserList,
  userName,
  userTypeId,
  updateUserData,
}: DepositModalProps) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [depositData, setDepositData] = useState<DepositData[]>([]);
  const [formData, setFormData] = useState({
    amount: "",
    remark: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    amount: "",
    remark: "",
    password: "",
  });
  const [touchedFields, setTouchedFields] = useState({
    amount: false,
    remark: false,
    password: false,
  });

  const getDepositUserDetails = async (id: string) => {
    const { response } = await authServices.getUserDetailsParent(id);
    setDepositData(response?.data || []);
  };

  useEffect(() => {
    if (userIds && show) getDepositUserDetails(userIds);
  }, [userIds, show]);

  useEffect(() => {
    if (!show) {
      setFormData({
        amount: "",
        remark: "",
        password: "",
      });
      setFormErrors({
        amount: "",
        remark: "",
        password: "",
      });
      setTouchedFields({
        amount: false,
        remark: false,
        password: false,
      });
      setDepositData([]);
    }
  }, [show]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true,
    }));

    validateForm({ ...formData, [name]: value });
  };

  const validateForm = (data = formData) => {
    let isValid = true;
    const errors: any = {};

    if (!data.amount || isNaN(Number(data.amount)) || Number(data.amount) <= 0) {
      errors.amount = "Amount is required.";
      isValid = false;
    } else {
      errors.amount = "";
    }

    if (!data.remark.trim()) {
      errors.remark = "Remark is required";
      isValid = false;
    } else {
      errors.remark = "";
    }

    if (!data.password) {
      errors.password = "Password is required";
      isValid = false;
    } else {
      errors.password = "";
    }

    setFormErrors(errors);
    return isValid;
  };

  const getChipsOut = async () => {
    const { response } = await authServices.getChipsOut({
      ...formData,
      amount: Number(formData.amount),
      crdr: 2,
      parent_id: depositData[0]?.parent_id?._id || userTypeId === 0 ? userIds : "",
      user_id: userIds,
    });

    if (response) {
      if (response.status) {
        snackbarUtil.success(response.msg);
        setDepositWithData(depositWithData - Number(formData.amount));
        if (updateUserData) {
          updateUserData(userIds, -Number(formData.amount));
        }
        clickHandler();
        setFormData({
          amount: "",
          remark: "",
          password: "",
        });
      } else {
        snackbarUtil.error(response.msg);
      }
    }
  };

  const handleSubmit = () => {
    if (validateForm()) {
      getChipsOut();
    } else {
      setTouchedFields({
        amount: true,
        remark: true,
        password: true,
      });
    }
  };

  const handleClose = () => {
    clickHandler();
    setFormData({
      amount: "",
      remark: "",
      password: "",
    });
  };

  return (
    <Modal show={show} onHide={handleClose} className="zoomIn" scrollable>
      <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
        <span>Withdraw to {userName}</span>
        <Button variant="light btn-sm" onClick={handleClose}>
          <i className="ri-close-line fs-xl align-middle"></i>
        </Button>
      </Modal.Header>
      <Modal.Body className="fs-md">
        <div className="form-floating">
          <Form.Control
            name="amount"
            type="number"
            placeholder="Withdraw Chips"
            value={formData.amount}
            onChange={handleInputChange}
            isInvalid={!!formErrors.amount && touchedFields.amount}
            onKeyDown={(e) => {
              if (
                e.key === "-" ||
                e.key === "e" ||
                e.key === "+" ||
                e.key === "E" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
          />
          <Form.Label>Withdraw Chips</Form.Label>
          {formErrors.amount && touchedFields.amount && (
            <Form.Control.Feedback type="invalid">
              {formErrors.amount}
            </Form.Control.Feedback>
          )}
        </div>

        <div className="d-flex justify-content-between mt-3">
          {depositData[0]?.parent_id && (
            <div className="py-1 px-2 rounded border border-dashed d-inline">
              {depositData[0]?.parent_id?.user_name}:{" "}
              <strong>
                {depositData[0]?.parent_id?.balance + +formData?.amount}
              </strong>
            </div>
          )}
          <div className="py-1 px-2 bg-light rounded border border-dashed d-inline ms-2">
            {depositData[0]?.name}:{" "}
            <strong>{depositData[0]?.balance - +formData?.amount}</strong>
          </div>
        </div>

        <div className="form-floating mt-3">
          <Form.Control
            name="remark"
            as="textarea"
            rows={5}
            id="Remarks"
            placeholder="Remarks"
            value={formData.remark}
            onChange={handleInputChange}
            isInvalid={!!formErrors.remark && touchedFields.remark}
          />
          <Form.Label htmlFor="Content">Remarks</Form.Label>
          {formErrors.remark && touchedFields.remark && (
            <Form.Control.Feedback type="invalid">
              {formErrors.remark}
            </Form.Control.Feedback>
          )}
        </div>

        <div className="form-floating mt-3 position-relative">
          <Form.Control
            name="password"
            type={passwordShow ? "text" : "password"}
            placeholder="Password"
            value={formData.password}
            onChange={handleInputChange}
            isInvalid={!!formErrors.password && touchedFields.password}
          />
          <Form.Label>Password</Form.Label>
          <Button
            variant="link"
            className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
            type="button"
            id="password-addon"
            onClick={() => setPasswordShow(!passwordShow)}
          >
            <i
              className={`${
                passwordShow ? "ri-eye-off-fill" : "ri-eye-fill"
              } align-middle`}
            ></i>
          </Button>
          {formErrors.password && touchedFields.password && (
            <Form.Control.Feedback type="invalid">
              {formErrors.password}
            </Form.Control.Feedback>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="text-end border-top">
        <Button variant="subtle-danger" onClick={handleSubmit}>
          Withdraw
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawModal;
