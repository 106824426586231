import { ListPagination } from "Common/ListPagination";
import TableEntry from "Common/TableEntry";
import moment from "moment";
import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface FancyPLTableProps {
  fancyPLData: FancyPLList[];
  page: number;
  limit: number;
  listTotal: ProfitLossTotal;
  total: number;
  handlePageFilter: (value: number, limit: number) => void;
}

const FancyPLTable = ({
  fancyPLData,
  page,
  limit,
  listTotal,
  total,
  handlePageFilter,
}: FancyPLTableProps) => {
  const navigate = useNavigate();
  const [showList, setList] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string>("");
  const [agentList, setAgentList] = useState<CasinoUserAgent[]>([]);

  const toggleList = (name?: string) => {
    setAgentList([]);
    setList(!showList);
    setSelectedUser(name || "");
  };

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <thead className="table-light">
            <tr>
              <th>No.</th>
              <th>Date</th>
              <th>Sports | Series</th>
              <th>Market | Match</th>
              <th>Stake</th>
              <th>View Bets</th>
            </tr>
          </thead>
          <tbody>
            {fancyPLData?.map((item, index) => {
              return (
                <tr>
                  <td>{((page - 1) * limit) + index + 1}</td>
                  <td>
                    <p className="mb-0">
                      {moment(item?.date_time).format("DD-MM-YY")}
                    </p>
                    <p className="mb-0">
                      {moment(item?.date_time).format(" hh:mm A")}
                    </p>
                  </td>
                  <td>
                    <strong className="mb-0 text-success">Cricket</strong>
                    <p className="mb-0 text-muted">{item?.series_name}</p>
                  </td>
                  <td>
                    <strong className="cursor-pointer">
                      {item?.event_name}
                    </strong>
                    <p className="mb-0 text-muted">{item?.match_name}</p>
                  </td>
                  <td className="text-info cursor-pointer">{item?.stack}</td>
                  <td>
                    <Button
                      variant="success"
                      className="btn-sm"
                      onClick={() =>
                        navigate(`/fancy-stack/${item?.match_id}/${item?._id}`)
                      }
                    >
                      User Stake
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}></th>
              <th>Total</th>
              <th
                colSpan={2}
                className={`text-${total < 0 ? "danger" : "success"}`}
              >
                {total || 0}
              </th>
            </tr>
          </tfoot>
        </Table>

        <nav aria-label="Page navigation" className="px-3 d-flex flex-column flex-sm-row align-items-center">
          <TableEntry
            limit={limit}
            handlelimitFilter={(value: number) => handlePageFilter(page, value)}
          />
          <ListPagination
            listTotal={listTotal}
            handlePageFilter={(value: number) => handlePageFilter(value, limit)}
          />
        </nav>
      </div>
      <Modal
        show={showList}
        onHide={() => toggleList()}
        className="zoomIn"
        scrollable
      >
        <Modal.Header className="modal-title fw-bold d-flex justify-content-between pb-1">
          <div className="d-flex align-items-center">
            Parent List of{" "}
            <span className="text-muted fw-normal mx-2">({selectedUser})</span>
          </div>
          <Button variant="light btn-sm" onClick={() => toggleList()}>
            <i className="ri-close-line fs-xl align-middle"></i>
          </Button>
        </Modal.Header>
        {agentList?.map((item) => {
          return (
            <Modal.Body>
              {item.name}({item.user_name})
            </Modal.Body>
          );
        })}
      </Modal>
    </>
  );
};

export default FancyPLTable;
