import BreadCrumb from "Common/BreadCrumb";
import Select from "react-select";
import { Container, Card, Col, Form, Row, Button } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { authServices } from "Utils/auth/services";
import { generateOptions } from "Utils/searchFilter";
import { useParams } from "react-router-dom";
import BetHistoryTable from "Common/Tables/BetHistoryTable";

const BetsHistory = () => {
  const { matchid, marketid, type, userid } = useParams();
  const [showFilter, setFilter] = useState(false);
  const [betType, setBetType] = useState<string>("open");
  const [betsData, setBetsData] = useState<BetHistoryList[]>([]);
  const [searchList, setSearchList] = useState<SearchSelection[]>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [odds, setOdds] = useState<string>("");
  const [stack, setStack] = useState<string>("");
  const [limit, setLimit] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [listTotal, setListTotal] = useState<ProfitLossTotal>({
    total: 0,
    page: 0,
  });

  const selectionOptions = useMemo(
    () => generateOptions(searchList || [], "selection_id", "selection_name"),
    [searchList]
  );

  const geOpenBetsList = async (filters: any = {}) => {
    const { response } = await authServices.getBetHistoryList({
      limit: limit,
      page: page,
      match_id: matchid,
      ...filters,
    });
    if (response?.data && response?.data?.data) {
      setBetsData(response?.data?.data);
      setListTotal({
        total: response?.data?.metadata[0]
          ? response?.data?.metadata[0]?.total / (filters?.limit || limit)
          : 0,
        page: response?.data?.metadata[0]
          ? response?.data?.metadata[0]?.page
          : 0,
      });
    } else {
      setBetsData([]);
      setListTotal({
        total: 0,
        page: 1,
      });
    }
  };

  const getSportSearchList = async (filters: any = {}) => {
    const { response } = await authServices.getSportsSearchList({
      type: "viewBet",
      ...filters,
    });
    if (response?.data) {
      setSearchList(response?.data);
    }
  };

  useEffect(() => {
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });

    let searchPayload: SearchPayload = {};
    if (type && type == "2") {
      searchPayload.fancy_id = marketid;
    } else {
      searchPayload.market_id = marketid;
    }

    getSportSearchList({
      search: searchPayload,
    });
  }, []);

  const handlefilterSubmit = () => {
    let filterData: SearchPayload = {};
    if (betType === "1" || betType === "0")
      filterData.is_back = Number(betType);
    if (Number(selectedId)) filterData.selection_id = Number(selectedId);
    if (Number(odds)) filterData.odds = Number(odds);
    if (Number(stack)) filterData.stack = Number(stack);
    if (userid && userid != "null") {
      filterData.user_id = userid;
    }

    geOpenBetsList({
      search: filterData,
    });
  };

  const handleSearchFilter = (id: string) => {
    let payload: SearchPayload = {
      selection_id: Number(id),
    };
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }

    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(odds)) payload.odds = Number(odds);
    if (Number(stack)) payload.stack = Number(stack);
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    setSelectedId(id);
    geOpenBetsList({
      search: payload,
    });
  };

  const handleClearSelection = () => {
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    setSelectedId("");
    setBetType("");
    setOdds("");
    setStack("");
    geOpenBetsList({
      search: payload,
    });
  };

  const handleChange = (e: any) => {
    let betType = e.target.value;
    setBetType(e.target.value);

    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }

    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(selectedId)) payload.selection_id = Number(selectedId);
    if (Number(odds)) payload.odds = Number(odds);
    if (Number(stack)) payload.stack = Number(stack);
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });
  };

  const handleOddsStake = (e: any, type: string) => {
    let value = e.target.value;

    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (betType === "1" || betType === "0") payload.is_back = Number(betType);
    if (Number(selectedId)) payload.selection_id = Number(selectedId);

    if (type === "odds") {
      setOdds(value);
      payload.odds = Number(value);
    } else {
      setStack(value);
      payload.stack = Number(value);
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
    });
  };

  const handlePageFilter = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit);
    let payload: SearchPayload = {};
    if (type && type == "2") {
      payload.fancy_id = marketid;
    } else {
      payload.market_id = marketid;
    }
    if (userid && userid != "null") {
      payload.user_id = userid;
    }

    geOpenBetsList({
      search: payload,
      page: page,
      limit: limit,
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div
          className="position-relative mb-2 d-flex justify-content-between align-items-center"
          style={{ minHeight: "43px" }}
        >
          <BreadCrumb title={"View Bets"} pageTitle="Dashboard" back />
          <Button variant="subtle-dark" onClick={() => setFilter(!showFilter)}>
            <i className="fs-lg align-middle ri-filter-3-line"></i> Filter
          </Button>
        </div>
        <>
          {showFilter ? (
            <Card>
              <Card.Body>
                <Form>
                  <Row className="gy-3 align-items-end">
                    <Col lg={3}>
                      <Form.Label>Search Selection Name</Form.Label>
                      <Select
                        isClearable
                        options={selectionOptions}
                        value={
                          selectionOptions.find(
                            (option: any) => option.value === selectedId
                          ) || null
                        }
                        className="customSelect"
                        onChange={(e: any) => {
                          if (e) {
                            handleSearchFilter(e.value);
                          } else {
                            handleClearSelection();
                          }
                        }}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Search User Name</Form.Label>
                      <Select
                        isClearable
                        options={[]}
                        className="customSelect"
                      />
                    </Col>

                    <Col lg={3}>
                      <Form.Label>Bet Type</Form.Label>
                      <Form.Select
                        id="floatingSelect"
                        aria-label="Floating label select example"
                        value={betType}
                        onChange={handleChange}
                      >
                        <option selected>Choose...</option>
                        <option value="">All</option>
                        <option value="1">Back</option>
                        <option value="0">Lay</option>
                      </Form.Select>
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Search By Rate</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Rate"
                        value={odds}
                        onChange={(e: any) => handleOddsStake(e, "odds")}
                      />
                    </Col>
                    <Col lg={3}>
                      <Form.Label>Search By Stack</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Stack"
                        value={stack}
                        onChange={(e: any) => handleOddsStake(e, "stack")}
                      />
                    </Col>
                    <Col lg={3}>
                      <Button
                        className="me-2 btn-sm"
                        style={{ height: "38px" }}
                        onClick={() => handlefilterSubmit()}
                      >
                        <i className="fs-lg align-middle ri-filter-line"></i>{" "}
                        Submit
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="btn-sm"
                        style={{ height: "38px" }}
                        onClick={() => handleClearSelection()}
                      >
                        <i className="fs-lg align-middle ri-refresh-line"></i>{" "}
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          ) : null}
          <Card>
            <Card.Body>
              <BetHistoryTable
                betHistoryData={betsData}
                page={page}
                limit={limit}
                listTotal={listTotal}
                handlePageFilter={handlePageFilter}
              />
            </Card.Body>
          </Card>
        </>
      </Container>
    </div>
  );
};

export default BetsHistory;
