import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

const BalanceTable = () => {
  const { exposure, balance, p_l } = useParams();

  return (
    <>
      <div className="table-responsive">
        <Table className="table-centered align-middle table-nowrap table-striped">
          <tbody>
            <tr>
              <td>
                <span className="cursor-pointer text-info">Net Exposure</span>
              </td>
              <td
                className={`text-${
                  exposure && Number(exposure) < 0 ? "danger" : "success"
                }`}
              >
                {exposure}
              </td>
            </tr>
            <tr>
              <td>
                <span className="cursor-pointer text-info">P | L</span>
              </td>
              <td
                className={`text-${
                  p_l && Number(p_l) < 0 ? "danger" : "success"
                }`}
              >
                {p_l}
              </td>
            </tr>
            <tr>
              <td>
                <span className="cursor-pointer text-info">
                  Available Credit
                </span>
              </td>
              <td
                className={`text-${
                  balance && Number(balance) < 0 ? "danger" : "success"
                }`}
              >
                {balance}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default BalanceTable;
