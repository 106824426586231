import React from 'react';
import { Card, Col, Form, Image } from 'react-bootstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import authemail from "assets/images/auth/email.png";

const Verification = () => {
       return (
        <ParticlesAuth>
            <React.Fragment>
                <Col xxl="6" className="mx-auto">
                    <Card className="mb-0 border-0 shadow-none mb-0">
                        <Card.Body className="p-sm-5 m-lg-4">
                            <div className="text-center mt-2">
                                <h5 className="fs-3xl w-75 mx-auto">Security Code Verification Using Telegram App</h5>
                                <p className="text-muted mb-4">Enter 6-digit code from your telegram bot Resend Code</p>
                                <div className="pb-4">
                                    <Image src={authemail} alt="" className="avatar-md" />
                                </div>
                            </div>
                            <div className="p-2 mt-3">
                                <Form action="#">
                                    <Form.Group className='d-flex gap-3'>
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                        <Form.Control type='number' className="form-control text-center fs-3 py-3" name="code" max='1' maxLength={2} />
                                    </Form.Group>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </React.Fragment>
        </ParticlesAuth>
    );
};

export default Verification;