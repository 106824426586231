import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import _ from "lodash";
import { useEffect, useState } from "react";
import { authServices } from "Utils/auth/services";
import { useParams } from "react-router-dom";
import { getLastTimeOfDay, getStartTimeOfDay } from "Utils/datefilter";
import SportsWisePLTable from "Common/Tables/SportWisePLTable";

interface SportsPLProps {
  filter?: boolean;
  isCasino: boolean;
  activeTab: string;
}

interface Payload {
  from_date: string;
  to_date: string;
  user_id?: string;
  international_casinos?: number;
  search?: {
    casinoProvider?: string;
  };
}

const SportsPL = ({ filter, isCasino, activeTab }: SportsPLProps) => {
  const { userid } = useParams();
  const [startDate, setStartDate] = useState<string>(
    getStartTimeOfDay(new Date())
  );
  const [endDate, setEndDate] = useState<string>(getLastTimeOfDay(new Date()));
  const [sports, setSports] = useState<SportsList[]>([]);
  const [sportsData, setSportsData] = useState<SportWisePlList[]>([]);
  const [parentId, setParentId] = useState<string>("null");

  const userId =
    userid && userid != "null" ? userid : localStorage.getItem("userId");

  const geOpenBetsList = async (filters: any = {}) => {
    const { response } = await authServices.getSportWisePL({
      user_id: userId,
      ...filters,
    });

    if (response.status == true) {
      let userPlList = response.data.users;
      userPlList = _.orderBy(
        userPlList,
        ["user_type_id", "user_name"],
        ["desc", "asc"]
      );
      let gameList = Object.keys(response.data.sports).map((data) => ({
        name: data,
        lower_name: "",
        total: 0,
      }));
      let a = {
        name: "Commission",
        lower_name: "",
        total: 0,
      };
      let b = {
        name: "Total With Commission",
        lower_name: "",
        total: 0,
      };
      let c = {
        name: "Total",
        lower_name: "",
        total: 0,
      };
      gameList.push(c);
      gameList.push(a);
      gameList.push(b);
      for (let i = 0; i < userPlList.length; i++) {
        for (let j = 0; j < gameList.length; j++) {
          gameList[j].lower_name = gameList[j].name.toLowerCase();
          gameList[j].lower_name = gameList[j].lower_name.replace(/ /g, "_");
          if (userPlList[i][gameList[j].name.toLowerCase()] == undefined) {
            userPlList[i][
              gameList[j].name.toLowerCase().replace(/ /g, "_")
            ] = 0;
          }
        }
      }
      for (let i = 0; i < userPlList.length; i++) {
        userPlList[i].total_with_commission =
          userPlList[i].total + userPlList[i].commission;
      }
      for (let i = 0; i < gameList.length; i++) {
        gameList[i].total = 0;
        for (let j = 0; j < userPlList.length; j++) {
          if (
            userPlList[j][gameList[i].lower_name] == undefined ||
            userPlList[j][gameList[i].lower_name] == null ||
            userPlList[j][gameList[i].lower_name] == ""
          ) {
            gameList[i].total = gameList[i].total + 0;
          } else {
            gameList[i].total =
              gameList[i].total + userPlList[j][gameList[i].lower_name];
          }
        }
      }

      setSportsData(userPlList);
      setParentId(response?.parent_id);
      setSports(gameList);
    }
  };

  useEffect(() => {
    let payload: Payload = {
      from_date: startDate,
      to_date: endDate,
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    if (activeTab === "SportsPL" &&  !isCasino) {
      geOpenBetsList(payload);
    }

    if (isCasino &&  activeTab === "QTechPL") {
      geOpenBetsList(payload);
    }
      
  }, [activeTab]);

  const handlefilterSubmit = () => {
    let payload: Payload = {
      from_date: startDate,
      to_date: endDate,
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    geOpenBetsList(payload);
  };

  const handleClearSelection = () => {
    let payload: Payload = {
      from_date: getStartTimeOfDay(new Date()),
      to_date: getLastTimeOfDay(new Date()),
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    geOpenBetsList(payload);
    setStartDate(getStartTimeOfDay(new Date()));
    setEndDate(getLastTimeOfDay(new Date()));
  };

  const handleUserDetail = (user: string) => {
    let payload: Payload = {
      from_date: startDate,
      to_date: endDate,
      user_id: user && user != "null" ? user : userId || "",
    };
    if (isCasino) {
      payload.international_casinos = 1;
      payload.search = { casinoProvider: "QT" };
    }

    geOpenBetsList(payload);
  };

  return (
    <>
      {filter ? (
        <Card>
          <Card.Body>
            <Form>
              <Row className="gy-3 align-items-end">
                <Col lg={3}>
                  <Form.Label>Start Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [startDate],
                    }}
                    value={startDate}
                    onChange={([date]: [Date]) => {
                      setStartDate(getStartTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>End Date</Form.Label>
                  <Flatpickr
                    className="form-control"
                    pl
                    options={{
                      enableTime: false,
                      dateFormat: "Y-m-d",
                      defaultDate: [endDate],
                    }}
                    value={endDate}
                    onChange={([date]: [Date]) => {
                      setEndDate(getLastTimeOfDay(new Date(date)));
                    }}
                  />
                </Col>
                <Col lg={3}>
                  <Form.Label>Search User Name</Form.Label>
                  <Select isClearable options={[]} className="customSelect" />
                </Col>

                <Col lg={3}>
                  <Button
                    className="me-2 btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handlefilterSubmit()}
                  >
                    <i className="fs-lg align-middle ri-filter-line"></i> Submit
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="btn-sm"
                    style={{ height: "38px" }}
                    onClick={() => handleClearSelection()}
                  >
                    <i className="fs-lg align-middle ri-refresh-line"></i> Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Body>
          <SportsWisePLTable
            sportsPLData={sportsData}
            sports={sports}
            parentId={parentId}
            handleUserDetail={handleUserDetail}
            isEvent={false}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default SportsPL;
