import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  InputGroup,
  Nav,
  Tab,
  Form,
} from "react-bootstrap";
import BreadCrumb from "Common/BreadCrumb";
import { authServices } from "Utils/auth/services";
import snackbarUtil from "Utils/snackBarUtil";

interface ResponsePayload {
  data: Response;
  msg: string;
  status: boolean;
}

interface Response {
  bot: string;
  connection_id: string;
  message: string;
}

const SecureAuth = () => {
  const [enable, setEnable] = useState(false);
  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const [telegramPassword, setTelegramPassword] = useState<string>("");
  const [teleData, setTeleData] = useState<ResponsePayload>();
  const [formErrors, setFormErrors] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  const Authentication = () => {
    setEnable(!enable);
  };

  const handleTelegramCode = async () => {
    if (!telegramPassword) {
      setFormErrors("Password is required");
      return;
    } else {
      setFormErrors("");
    }

    const { response } = await authServices.telegramCode({
      password: telegramPassword,
    });
    if (response?.status) {
      const successMessage = response?.msg;
      snackbarUtil.success(successMessage);
      // getUserList();
      setTeleData(response);
    } else {
      const errorMessage =
        response?.msg || "An error occurred. Please try again.";
      snackbarUtil.error(errorMessage);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    const updatedCode = [...code];
    updatedCode[index] = value;
    setCode(updatedCode);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTelegramPassword(e.target.value);
  };

  const handleSubmitCode = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Secure Authentication Verification"
            pageTitle="Dashboard"
          />
          <Col lg={8} className="mx-auto mt-4">
            <Card>
              <Card.Body className="text-center">
                <h5>
                  Secure Auth Verification Status:{" "}
                  <Button
                    variant="sm"
                    className={`${enable ? "btn-success" : "btn-danger"}`}
                    onClick={Authentication}
                  >
                    {enable ? "Enabled" : "Disabled"}
                  </Button>
                </h5>
                <p className="fs-md">
                  Please select below option to enable secure auth verification
                </p>

                {enable ? (
                  <div className="p-2 mt-3">
                    <h4 className="text-primary">Security Code Verification</h4>
                    <p className="fs-xl">
                      Enter 6-digit code to disable Secure Auth
                    </p>
                    <Form onSubmit={handleSubmitCode} className="w-50 mx-auto">
                      <Form.Group className="d-flex gap-3">
                        {Array(6)
                          .fill("")
                          .map((_, index) => (
                            <Form.Control
                              key={index}
                              type="text"
                              className="form-control text-center fs-3 py-2"
                              name="code"
                              value={code[index]}
                              maxLength={1} // To allow only one digit in each input
                              onChange={(e) =>
                                handleCodeChange(index, e.target.value)
                              }
                            />
                          ))}
                      </Form.Group>
                      <Button type="submit" className="mt-3">
                        Submit Code
                      </Button>
                    </Form>
                  </div>
                ) : (
                  <Tab.Container>
                    <Nav
                      as="ul"
                      variant="pills"
                      className="arrow-navtabs nav-success justify-content-center bg-light mb-3"
                    >
                      <Nav.Item as="li">
                        <Nav.Link eventKey="telegram">
                          Enable Using Telegram
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content className="text-muted">
                      <Tab.Pane eventKey="telegram">
                        <Col lg={6} className="mx-auto">
                          <InputGroup hasValidation>
                            <Form.Control
                              type={passwordShow ? "text" : "password"}
                              aria-label="password"
                              placeholder="Enter your login password"
                              value={telegramPassword}
                              onChange={handlePasswordChange} // Handle password input change
                              isInvalid={!!formErrors}
                            />
                            <Button
                              variant="link"
                              className="position-absolute end-0 top-0 bottom-0 text-decoration-none text-muted password-addon"
                              type="button"
                              id="password-addon"
                              onClick={() => setPasswordShow(!passwordShow)}
                            >
                              <i
                                className={`${
                                  !passwordShow
                                    ? "ri-eye-off-fill"
                                    : "ri-eye-fill"
                                } align-middle`}
                              ></i>
                            </Button>

                            <Button
                              variant="primary"
                              onClick={handleTelegramCode}
                            >
                              Get Connection ID
                            </Button>
                            {formErrors && (
                              <Form.Control.Feedback type="invalid">
                                {formErrors} Error
                              </Form.Control.Feedback>
                            )}
                          </InputGroup>
                        </Col>
                        {teleData?.status && (
                          <div className="mt-3">
                            <h6 className="fs-md">{teleData.data.message}</h6>
                            <p className="fs-md">
                              Find{" "}
                              <span className="text-primary">
                                {teleData.data.bot}
                              </span>{" "}
                              in your telegram and type{" "}
                              <span className="badge bg-secondary-subtle text-secondary">
                                /start
                              </span>{" "}
                              command. Bot will respond to you.
                            </p>
                            <p className="fs-md">
                              After this, type{" "}
                              <span className="badge bg-secondary-subtle text-secondary">
                                /connect {teleData.data.connection_id}
                              </span>{" "}
                              and send it to BOT.
                            </p>
                            <p className="fs-md">
                              Now your telegram account will be linked with your
                              website account and 2-Step verification will be
                              enabled.
                            </p>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SecureAuth;
